// @flow

import React from 'react';
import {useSelector} from 'react-redux';

import Spinner from './Spinner';

import {arePersisted} from '../redux/selectors';

type Props = {
  +children: any,
  +keys: Array<string> // wait for these to be rehydrated
}

export default function PersistGate(props: Props) {

  const {keys, children} = props;

  const persisted = useSelector(state => arePersisted(state, keys));
  if (persisted) {
    return children;
  }

  return <Spinner fullScreen />

}

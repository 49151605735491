// @flow

//import { createSelector } from 'reselect';
import {getCheckoutState} from './index';

function getFirstName(state: Object): ?string {
  return getCheckoutState(state).firstName;
}

function getLastName(state: Object): ?string {
  return getCheckoutState(state).lastName;
}

function getEmail(state: Object): ?string {
  return getCheckoutState(state).email;
}

function getPhoneNumber(state: Object): ?string {
  return getCheckoutState(state).phoneNumber;
}

function getShippingType(state: Object): ?string {
  return getCheckoutState(state).shippingType;
}

function getFullShippingAddress(state: Object): ?string {
  const shippingType = getShippingType(state);
  if (shippingType) {
    const checkoutState = getCheckoutState(state);
    const postLocker = checkoutState[shippingType];
    if (postLocker) {
      return postLocker;
    } else {
      const {area, city, addressLine1, postCode} = checkoutState;
      if (area && city && addressLine1) {
        const address = `${addressLine1}, ${city}, ${area} rajonas / savivaldybė`
        return postCode ? `${address}, LT-${postCode}` : address;
      }
    }
  }
}

function getStripeClientSecret(state: Object): ?string {
  return getCheckoutState(state).stripeClientSecret;
}

function isInitialState(state: Object): boolean {
  return Object.keys(state).length === 0;
}

export {
  getShippingType,
  getFullShippingAddress,
  getFirstName,
  getLastName,
  getEmail,
  getPhoneNumber,
  getStripeClientSecret,
  isInitialState
}

// @flow

import React from 'react';

import Text from 'atomize/dist/atoms/text/Text';
import Container from 'atomize/dist/atoms/grid/Container';
import Row from 'atomize/dist/atoms/grid/Row';
import Col from 'atomize/dist/atoms/grid/Col';
import Div from 'atomize/dist/atoms/div/Div';
import Anchor from 'atomize/dist/atoms/anchor/Anchor';
import Button from 'atomize/dist/atoms/button/Button';
import { BsArrowDown as DownIcon } from 'react-icons/bs';
import {FaCheck as CheckIcon} from 'react-icons/fa';

import Collapse from '../Collapse';
import Spinner from '../Spinner';
import Box from './Box';

type StepProps = {
  +id: string,
  +title: string,
  +isActive: boolean,
  +isDisabled?: boolean,
  +isLoading?: boolean,
  +isComplete?: boolean,
  +onEdit: Function,
  +onSubmit: Function,
  +summary?: any,
  +submitBtnTitle?: any,
  +children: any
}

function SubmitBtnContent(props: Object) {

  const {title, isLoading} = props;

  if (title) {
    return (
      <>
        {title}
        {isLoading && <Spinner />}
      </>
    );
  }

  return (
    <>
      Išsaugoti ir Tęsti
      {
        //<DownIcon size='1.25rem' />
        isLoading ? <Spinner /> : <DownIcon className='ui-icon' size='1.25rem' />
      }

    </>
  );
}

export default function Step(props: StepProps) {

  const {id, submitBtnTitle, isActive, isDisabled, isLoading, isComplete, summary, onSubmit, onEdit, title, children} = props;

  const isSummaryVisible = isComplete && !isActive;
  const isOpen = isActive || !!isComplete;

  const anchorComp = isSummaryVisible
    ? <Anchor textSize='tiny' textColor='white' hoverTextColor='white' textDecor="underline" onClick={onEdit}>
        Pakeisti
      </Anchor>
    : <></>

  return (
    <Box isOpen={isOpen} title={<>{isSummaryVisible && <Text tag='span' p={{r: '0.5rem'}} textColor='success600'><CheckIcon /> </Text>} {title}</>} anchorComp={anchorComp}>
      {
        isActive &&
        <form onSubmit={isDisabled ? () => {} : onSubmit}>
          {children}
          <Row>
            <Col size={{xs: '12', sm: '6'}} />
            <Col size={{xs: '12', sm: '6'}}>
              <Button disabled={isDisabled} w='100%' m={{t: '2rem'}} rounded='lg' shadow="3" hoverShadow="4" bg='success700' hoverBg='success800' h='2.5rem' textSize='caption'>
                <SubmitBtnContent title={submitBtnTitle} isLoading={isLoading} />
              </Button>
            </Col>
          </Row>
        </form>
      }
      {
        isSummaryVisible && summary
      }
    </Box>
  );
}

// @flow

import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { navigate } from "gatsby";

import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import Div from 'atomize/dist/atoms/div/Div';
import Text from 'atomize/dist/atoms/text/Text';

import FormLabel from '../FormLabel';
import Spinner from '../Spinner';
import Step from './Step';


import {
  getFirstName, getLastName, getEmail, getPhoneNumber, getShippingType, getFullShippingAddress, getStripeClientSecret
} from '../../redux/selectors/checkoutSelectors';
import {getCartItems, isCartOpen} from '../../redux/selectors/cartSelectors';
import {addNotification} from '../../redux/actions/notificationsActions';

import './stripe.css';

const stripeElemOptions = {
  style: {
    base: {
      fontFamily: 'inherit',
      fontSize: 'inherit',
      height: 'inherit',
      color: 'inherit',
      //display: 'inherit'
      // '::placeholder': {
      //   color: 'black'
      // }
      // '::hover': {
      //   color: 'inherit'
      // }
    },
    invalid: {
      color: '#f4541d'
    }
  }
}

function StyledCardElem(props: Object) {

  const [focused, setFocused] = useState(false);
  const {onChange, onReady} = props;

  return (
    <Div border='1px solid' borderColor={focused ? 'gray700' : 'gray500'} textSize='caption' h='2.5rem' rounded='md' p='0.75rem' color='black'>
      <CardElement onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} options={stripeElemOptions} onChange={onChange} onReady={onReady} />
    </Div>
  );
}


function StripeCheckout(props: Object) {

  const dispatch = useDispatch();

  const [processing, setProcessing] = useState(false);
  const [filled, setFilled] = useState(false);

  // store in redux checkout?
  //const [clientSecret, setClientSecret] = useState('');

  const stripe = useStripe();
  const elements = useElements();
  const hasStripeLoaded = !!stripe && !!elements;

  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const email = useSelector(getEmail);
  const phoneNumber = useSelector(getPhoneNumber);
  const shippingType = useSelector(getShippingType);
  const fullAddr = useSelector(getFullShippingAddress);
  const cartItems = useSelector(getCartItems);
  const cartOpen = useSelector(isCartOpen);
  const clientSecret = useSelector(getStripeClientSecret);

  // React can't tell if objects inside an array changed
  const trackableCartItems = JSON.stringify(cartItems);

  useEffect(() => {

    const isPrePayInfoFilled = !!(firstName && lastName && email && phoneNumber && shippingType && fullAddr && cartItems && cartItems.length);
    const canCreateIntent = isPrePayInfoFilled && !cartOpen && !processing;

    if (canCreateIntent) {
      dispatch({
        type: 'CREATE_ORDER_INTENT',
        payload: {
          cartItems,
          payment: {
            provider: 'stripe',
            ccy: 'EUR'
          },
          customer: {
            firstName, lastName, email, phoneNumber
          },
          shipping: {
            shippingType,
            fullAddr
          }
        }
      });
    }

  }, [firstName, lastName, email, phoneNumber, shippingType, fullAddr, trackableCartItems, cartOpen]);

  const handleSubmit = async(evt) => {
    evt.preventDefault();
    setProcessing(true);

    const res = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });

    if (res.error) {
      setProcessing(false);
      dispatch(addNotification('PROCESS_PAYMENT_FAIL', `Apmokėjimas nepavyko. ${res.error.message}`));
    } else {
      navigate('/success', {
        replace: true,
        state: {
          email
        }
      });
      
    }

  }

  return (
    <Step {...props} submitBtnTitle='Mokėti kortele' isLoading={processing} isDisabled={!hasStripeLoaded || processing || !filled} onSubmit={handleSubmit}>
      <StyledCardElem onChange={evt => setFilled(evt.complete)} onReady={() => setFilled(false)} />
      {
        processing &&
        <Spinner fullScreen>
          <Text textSize='subheader'>Mokėjimas apdorojamas, prašome palaukti...</Text>

        </Spinner>
      }
    </Step>
  )
}

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY);

export default function PaymentStep(props: Object) {

  return (
    <Elements stripe={stripePromise} options={{locale: 'lt'}}>
      <StripeCheckout {...props} />
    </Elements>
  );
}

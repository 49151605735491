// @flow

import React from 'react';

import Text from 'atomize/dist/atoms/text/Text';
import Container from 'atomize/dist/atoms/grid/Container';
import Row from 'atomize/dist/atoms/grid/Row';
import Col from 'atomize/dist/atoms/grid/Col';
import Div from 'atomize/dist/atoms/div/Div';

import Collapse from '../Collapse';

type BoxProps = {
  +title: any,
  +titleBg?: string,
  +titleTextColor?: string,
  +id?: string,
  +isOpen: boolean,
  +anchorComp?: any,
  +children?: any
}

export default function Box(props: BoxProps) {

  const {id, isOpen, anchorComp, title, titleTextColor, titleBg, children} = props;

  return (
    <Div p={{b: '1rem'}} id={id}>
      <Div bg={titleBg || 'black600'} p={{t: '1rem', b: '1rem', l: '1.5rem', r: '2rem'}} rounded='xs'>
        <Row>
          <Col textAlign='left'>
            <Text tag='span' textColor={titleTextColor || 'white'} textWeight='600'>{title}</Text>
          </Col>
          {
            anchorComp &&
            <Col textAlign='right'>
              {anchorComp}
            </Col>
          }

        </Row>
      </Div>

      <Collapse isOpen={isOpen}>
        <Div border='1px solid' borderColor='gray400' p={{t: '1rem', b: '1rem'}}>
          <Container>
            {children}
          </Container>
        </Div>
      </Collapse>
    </Div>
  );
}

// @flow

import React, {useState} from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Text from 'atomize/dist/atoms/text/Text';
import Div from 'atomize/dist/atoms/div/Div';

import SearchDropdown from '../SearchDropdown';
import Link from '../MenuLink';

const options = [
  {
    value: 'Akmenės'
  },
  {
    value: 'Alytaus'
  },
  {
    value: 'Anykščių'
  },
  {
    value: 'Birštono'
  },
  {
    value: 'Biržų'
  },
  {
    value: 'Druskininkų'
  },
  {
    value: 'Elektrėnų'
  },
  {
    value: 'Gargždų'
  },
  {
    value: 'Ignalinos'
  },
  {
    value: 'Jonavos'
  },
  {
    value: 'Joniškio'
  },
  {
    value: 'Jurbarko'
  },
  {
    value: 'Kaišiadorių'
  },
  {
    value: 'Kalvarijos'
  },
  {
    value: 'Kauno'
  },
  {
    value: 'Kazlų Rūdos'
  },
  {
    value: 'Kelmės'
  },
  {
    value: 'Kėdainių'
  },
  {
    value: 'Klaipėdos'
  },
  {
    value: 'Kretingos'
  },
  {
    value: 'Kupiškio'
  },
  {
    value: 'Lazdijų'
  },
  {
    value: 'Marijampolės'
  },
  {
    value: 'Mažeikių'
  },
  {
    value: 'Molėtų'
  },
  {
    value: 'Neringos'
  },
  {
    value: 'Pagėgių'
  },
  {
    value: 'Pakruojo'
  },
  {
    value: 'Palangos'
  },
  {
    value: 'Panevėžio'
  },
  {
    value: 'Pasvalio'
  },
  {
    value: 'Plungės'
  },
  {
    value: 'Prienų'
  },
  {
    value: 'Radviliškio'
  },
  {
    value: 'Raseinių'
  },
  {
    value: 'Rietavo'
  },
  {
    value: 'Rokiškio'
  },
  {
    value: 'Skuodo'
  },
  {
    value: 'Šakių'
  },
  {
    value: 'Šalčininkų'
  },
  {
    value: 'Šiaulių'
  },
  {
    value: 'Šilalės'
  },
  {
    value: 'Šilutės'
  },
  {
    value: 'Širvintų'
  },
  {
    value: 'Švenčionių'
  },
  {
    value: 'Tauragės'
  },
  {
    value: 'Telšių'
  },
  {
    value: 'Trakų'
  },
  {
    value: 'Ukmergės'
  },
  {
    value: 'Utenos'
  },
  {
    value: 'Varėnos'
  },
  {
    value: 'Vilkaviškio'
  },
  {
    value: 'Vilniaus'
  },
  {
    value: 'Visagino'
  },
  {
    value: 'Zarasų'
  },
];

const AreaSelect = React.forwardRef((props: Object, ref: any) => {

  return (

      <SearchDropdown options={options} placeholder='Pasirinkite rajoną / savivaldybę' {...props}>

      </SearchDropdown>

  );

});

export default AreaSelect;

// @flow

import React from 'react';

import Dropdown from 'atomize/dist/atoms/dropdown/Dropdown';

import {
  BiChevronDown as DownIcon,
  BiChevronUp as UpIcon,
} from 'react-icons/bi';

export default function MyDropdown(props: Object) {

  const {children, ...otherProps} = props;

  return (
    <Dropdown openSuffix={<UpIcon className='ui-icon' size='1.25rem' />} closeSuffix={<DownIcon className='ui-icon' size='1.25rem' />} {...otherProps}>{children}</Dropdown>
  )
}

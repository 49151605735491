// @flow

import React from 'react';

import Input from 'atomize/dist/atoms/input/Input';
//import Label from 'atomize/dist/atoms/label/Label';
import Text from 'atomize/dist/atoms/text/Text';
import FormField from './FormField';

export default function FormInput(props: Object) {

  const {placeholder, required, label, error, register, name, registerOptions, ...otherProps} = props;

  return (
    <FormField label={label} required={required} error={error}>
      <Input borderColor={error ? 'danger700' : undefined} textSize='caption' h='2.5rem' placeholder={placeholder} m={{t: {xs: '0.5rem', sm: '0rem'}, b: {xs: '0.5rem', sm: '0rem'}}} {...register(name, registerOptions)} {...otherProps} />
    </FormField>
  )
}

// @flow

import React, {Fragment, useState, useEffect} from 'react';

import Input from 'atomize/dist/atoms/input/Input';
import Text from 'atomize/dist/atoms/text/Text';
import Div from 'atomize/dist/atoms/div/Div';

import Link from './MenuLink';
import Dropdown from './Dropdown';

type OptionItem = {
  +key?: string,
  +value: string,
  +group?: string
}


function DropdownItem(props: Object) {

  const {value, onClick} = props;

  return (
    <Link textSize='caption' forceLegacy d='block' hoverBg='info500' p={{t: '0.25rem', b: '0.25rem', l: { xs: '1rem', sm: '2.5rem'}}} onClick={onClick}>
      {value}
    </Link>
  )
}

type MenuItemsProps = {
  +options: Array<OptionItem>,
  +searchValue: string,
  +onSelect: (value: string) => void
}

type OptionItemsById = {
  [id: string]: Array<OptionItem>
}

function groupOptions(options: Array<OptionItem>): OptionItemsById {
  const groupedOptions = {};
  options.forEach(o => {

    if (groupedOptions[o.group]) {
      groupedOptions[o.group].push(o);
    } else {
      groupedOptions[o.group] = [o];
    }
  });

  return groupedOptions;
}

function MenuItems(props: MenuItemsProps) {

  const {options, onSelect, searchValue} = props;

  const filteredOptions = !searchValue ? options : options.filter(o => {
    return o.value.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0;
  });

  const groupedOptions = groupOptions(filteredOptions);

  const menuItems = [];
  Object.keys(groupedOptions).sort().forEach(groupName => {

    const group: Array<Object> = groupedOptions[groupName];

    group.forEach((o, i) => {
      const itemKey = o.key || o.value;
      if (i === 0) {
        menuItems.push(
          <Fragment key={`MenuItem:Fragment:${o.group}-${itemKey}`}>
            <Text bg='gray300' textWeight='600' textSize='caption' p={{t: '0.25rem', b: '0.25rem', l: '0.5rem'}}>{o.group}</Text>
            <DropdownItem key={itemKey} value={o.value} onClick={() => onSelect(o.value)} />
          </Fragment>
        );
      } else {
        menuItems.push(<DropdownItem key={itemKey} value={o.value} onClick={() => onSelect(o.value)} />)
      }
    });

  });

  return menuItems;
}


export default function SearchDropdown(props: Object) {

  const {options, placeholder, id, value, onChange, ...otherProps} = props;

  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  //const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    setSearchValue('');
  }, [isOpen]);

  return (
    <Dropdown h='2.5rem' id={id} isOpen={isOpen} onClick={() => setOpen(!isOpen)} menu={<>
        <Input h='2.5rem' textSize='caption' autoFocus placeholder='Ieškoti...' onChange={e => setSearchValue(e.target.value.trim())} />
        <Div maxH='200px' overflow='auto'>
          <MenuItems options={options} searchValue={searchValue} onSelect={value => {
              setOpen(false);
              onChange({
                target: {
                  value
                }
              });
            }}
          />
        </Div>
      </>} {...otherProps}
    >
      <Text textSize='caption' textColor='black'>
        {value || placeholder}
      </Text>

    </Dropdown>
  );

}

// @flow

import React, {useState} from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Text from 'atomize/dist/atoms/text/Text';
import Div from 'atomize/dist/atoms/div/Div';

import SearchDropdown from '../SearchDropdown';
import Link from '../MenuLink';

type Props = {
  +shippingType: 'omniva' | 'lpExpress'
}

const PostLockerSelect = React.forwardRef((props: Props, ref: any) => {

  const allLockersData = useStaticQuery(graphql`{
    allPostLockers {
      edges {
        node {
          data {
            lpExpress {
              name
              address
              city
            }
            omniva {
              name
              address
              city
            }
          }
        }
      }
    }
  }`);

  const {shippingType, ...otherProps} = props;
  const {omniva, lpExpress} = allLockersData.allPostLockers.edges[0].node.data;
  const lockers = shippingType === 'lpExpress' ? lpExpress : omniva;
  const id = shippingType === 'lpExpress' ? 'lpExpressSelect' : 'omnivaSelect';

  const options = lockers.map(locker => {
    const {address, city, name} = locker;
    const value = `${address} - ${name}`;

    return {
      value,
      key: `${shippingType}-${value}`,
      group: city
    }
  });

  return (

      <SearchDropdown id={id} options={options} placeholder='Pasirinkite paštomatą' {...otherProps}>

      </SearchDropdown>

  );

});

export default PostLockerSelect;

// @flow

import React from 'react';
import { FaSpinner as SpinIcon } from 'react-icons/fa';

import Div from 'atomize/dist/atoms/div/Div';
import Text from 'atomize/dist/atoms/text/Text';

type Props = {
  +size?: ?string,
  +fullScreen?: boolean
}

export default function Spinner(props: Object) {

  const {size, fullScreen, children} = props;

  if (fullScreen) {
    return (
      <Div className='overlay'>
        <Div className='center'>
          <Text textAlign='center'>
            <SpinIcon className='spinner' color='black' size={size || '3rem'} />
          </Text>
          <Div p={{t: '2rem'}}>
            {children}
          </Div>
        </Div>

      </Div>
    );
  }

  return (
    <Div tag='span' p={{l: '0.5rem', t: '0.25rem'}}>
      <SpinIcon className='spinner' size={size || '1.25rem'} />
      {children}
    </Div>
  );
}

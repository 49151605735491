// @flow

import React from 'react';

// TODO: make it an actual collapse, the atomize one is very basic and buggy for more complex use cases

export default function MyCollapse(props: Object) {
  const {children, isOpen} = props;

  if (isOpen) {
    return children;
  }
  //
  // return (
  //   <Collapse isOpen={isOpen}>
  //     {children}
  //   </Collapse>
  // );

  return <></>
}

// @flow

import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useForm, Controller } from "react-hook-form";

import Text from 'atomize/dist/atoms/text/Text';
import Container from 'atomize/dist/atoms/grid/Container';
import Row from 'atomize/dist/atoms/grid/Row';
import Col from 'atomize/dist/atoms/grid/Col';
import Div from 'atomize/dist/atoms/div/Div';
import Button from 'atomize/dist/atoms/button/Button';
import Input from 'atomize/dist/atoms/input/Input';
import Anchor from 'atomize/dist/atoms/anchor/Anchor';
//import Radiobox from 'atomize/dist/atoms/radiobox/Radiobox';
import Label from 'atomize/dist/atoms/label/Label';
import scrollTo from 'atomize/dist/functions/scrollTo';

import { BsArrowDown as DownIcon } from 'react-icons/bs';

import {
  RiShoppingBasketLine as CartIcon,
  RiMenuFill as MobileMenuIcon
} from 'react-icons/ri';

import PostLockerSelect from '../components/checkout/PostLockerSelect';
import AreaSelect from '../components/checkout/AreaSelect';
import CartSummary from '../components/checkout/CartSummary';
import Step from '../components/checkout/Step';
import PaymentStep from '../components/checkout/PaymentStep';
import Collapse from '../components/Collapse';
import FormInput from '../components/FormInput';
import FormField from '../components/FormField';
import Radiobox from '../components/Radiobox';
import HtmlHead from '../components/HtmlHead';

import PersistGate from '../components/PersistGate';

import {getTotalCartItemsQty} from '../redux/selectors/cartSelectors';
import {getCheckoutState} from '../redux/selectors';
import {getFullShippingAddress} from '../redux/selectors/checkoutSelectors';

function ShippingTypeOption(props: Object) {

  const {title, checked, onChange, children, register, ...otherProps} = props;

  const registerResult = register('shippingType', {required: 'Pasirinkite pristatymo būdą'})

  return (
    <Col size='12'>
      <Row>
        <Col>
          <Label textSize='caption' align='center' m={{ b: "0.5rem" }}>
            <Radiobox checked={checked} ref={registerResult.ref} onBlur={registerResult.onBlur} onChange={evt => {
                registerResult.onChange(evt);
                onChange(evt);
              }} name={registerResult.name} {...otherProps} /> {title}
          </Label>
        </Col>
        <Col textAlign='right'>
          <Text textSize='caption'>3 €</Text>
        </Col>
      </Row>
      {
        <Collapse isOpen={checked}>
          <Div p={{b: '1rem'}}>
            {children}
          </Div>
        </Collapse>
      }
    </Col>
  )
}

function PostLockerFormSelect(props: Object) {

  const {name, control, errors, getValues, defaultValue} = props;

  const lockerErr = errors && errors[name];

  return (
    <Div p={{b: '0.5rem'}}>
      <Controller defaultValue={defaultValue} name={name} control={control} rules={{validate: val => {
          // validate is memoized, isOpen doesn't work
          const shippingType = getValues('shippingType');
          return (shippingType && shippingType !== name) || !!val || 'Pasirinkite paštomatą';
        }}} render={({field}) => <FormField label='Paštomatas' required error={lockerErr}><PostLockerSelect borderColor={lockerErr ? 'danger700' : undefined} shippingType={name} {...field} /></FormField>} />
    </Div>
  )
}

function ShippingAddressSection(props: Object) {

  const {errors, control, register, getValues} = props;
  const {city, addressLine1, postCode, area} = useSelector(getCheckoutState);

  const areaErr = errors && errors.area;

  const validate = (val, errorMsg) => {
    const shippingType = getValues('shippingType');
    return (shippingType && shippingType !== 'post') || !!val || errorMsg;
  }

  return (
    <>

      <Controller name='area' defaultValue={area} control={control} rules={{validate: val => validate(val, 'Pasirinkite rajoną / savivaldybę') }} render={({field}) => <FormField required label='Rajonas / savivaldybė' error={areaErr}><AreaSelect borderColor={areaErr ? 'danger700' : undefined} {...field} /></FormField>} />

      <FormInput required label='Miestas / miestelis / kaimas' name='city' registerOptions={{validate: val => validate(val, 'Įveskite miesto, miestelio arba kaimo pavadinimą')}} register={register} error={errors.city} defaultValue={city} />

      <FormInput required label='Adresas' name='addressLine1' registerOptions={{validate: val => validate(val, 'Įveskite adresą')}} register={register} error={errors.addressLine1} defaultValue={addressLine1} />

      <FormInput label='Pašto kodas (tik skaičiai)' type='number' name='postCode' register={register} error={errors.postCode} defaultValue={postCode} />

    </>
  );
}

function ShippingStep(props: Object) {

  const { register, handleSubmit, getValues, control, formState: {errors} } = useForm({
    mode: 'onChange'
  });

  const {shippingType, omniva, lpExpress, address} = useSelector(getCheckoutState);
  const shippingAddress = useSelector(getFullShippingAddress);

  const dispatch = useDispatch();

  // redux should only track submitted form info
  const [uiShippingType, setUiShippingType] = useState('');
  useEffect(() => {
    setUiShippingType(shippingType);
  }, []);
  const _onShippingChange = newType => {
    setUiShippingType(newType);
  }

  const {activeStep, setActiveStep} = props;

  const id = 'shipping';
  const isComplete = !!shippingAddress;
  const isActive = activeStep === id;

  const titles = {
    post: 'Pristatymas į namus paštu',
    omniva: 'Atsiėmimas Omniva paštomate',
    lpExpress: 'Atsiėmimas LP Express paštomate'
  };

  return (
    <Step id={id} isActive={isActive} title='2. Pristatymas' onEdit={() => setActiveStep(id)} isComplete={isComplete} onSubmit={handleSubmit(data => {
        dispatch({
          type: 'UPDATE_CHECKOUT_INFO',
          payload: {
            ...data
          }
        });
        setActiveStep('payment');
      })} summary={
        <>
          <Row>
            <Text textColor='black600' textSize='caption'>{titles[uiShippingType]}</Text>
          </Row>
          <Row>
            <Text textColor='black600' textSize='caption'>{shippingAddress}</Text>
          </Row>
        </>
      }>
        <Row p={{b: '1rem'}}>
          <ShippingTypeOption register={register} checked={uiShippingType === 'post'} value='post' title={titles.post} onChange={() => _onShippingChange('post')}>
            <ShippingAddressSection errors={errors} getValues={getValues} register={register} control={control} />
          </ShippingTypeOption>

          <ShippingTypeOption register={register} checked={uiShippingType === 'omniva'} value='omniva' title={titles.omniva} onChange={() => _onShippingChange('omniva')}>
              <PostLockerFormSelect defaultValue={omniva} name='omniva' control={control} errors={errors} getValues={getValues} />
          </ShippingTypeOption>

          <ShippingTypeOption register={register} checked={uiShippingType === 'lpExpress'} value='lpExpress' title={titles.lpExpress} onChange={() => _onShippingChange('lpExpress')}>
              <PostLockerFormSelect defaultValue={lpExpress} name='lpExpress' control={control} errors={errors} getValues={getValues} />
          </ShippingTypeOption>

          {
            errors && errors.shippingType && <Text textSize='caption' textColor='danger700'>{errors.shippingType.message}</Text>
          }

        </Row>
    </Step>
  )
}

function CustomerInfoStep(props: Object) {

  const { register, handleSubmit, formState: {errors} } = useForm({
    mode: 'onChange'
  });
  const dispatch = useDispatch();
  const {firstName, lastName, email, phoneNumber} = useSelector(getCheckoutState);
  const isComplete = !!(firstName && lastName && email && phoneNumber);

  const {activeStep, setActiveStep} = props;

  const id = 'customer-info';
  const isActive = activeStep === id;

  return (
    <Step id={id} isActive={isActive} title='1. Pirkėjo informacija' onEdit={() => setActiveStep(id)} isComplete={isComplete} summary={
        <>
          <Row>
            <Text textColor='black600' textSize='caption'>{firstName} {lastName}</Text>
          </Row>
          <Row>
            <Text textColor='black600' textSize='caption'>{email}, {phoneNumber}</Text>
          </Row>
        </>
      } onSubmit={handleSubmit(data => {
        dispatch({
          type: 'UPDATE_CHECKOUT_INFO',
          payload: {
            ...data
          }
        });
        setActiveStep('');
      }) }>
      {
        <>
          <Row>
            <Col size={{xs: '12', md: '6'}}>
              <FormInput label='Vardas' name='firstName' required registerOptions={{required: 'Įveskite savo vardą'}} register={register} error={errors.firstName} defaultValue={firstName} />
            </Col>
            <Col size={{xs: '12', md: '6'}}>
              <FormInput label='Pavardė' name='lastName' required registerOptions={{required: 'Įveskite savo pavardę'}}  register={register} error={errors.lastName} defaultValue={lastName} />
            </Col>
          </Row>
          <Row p={{t: '1rem'}}>
            <Col size={{xs: '12', md: '6'}}>
              <FormInput label='El. paštas' type='email' name='email' required registerOptions={{required: 'Įveskite savo el.pašto adresą'}} register={register} error={errors.email} defaultValue={email} />
            </Col>
            <Col size={{xs: '12', md: '6'}}>
              <FormInput label='Telefono nr.' type='tel' name='phoneNumber' required registerOptions={{required: 'Įveskite savo telefono numerį'}} register={register} error={errors.phoneNumber} defaultValue={phoneNumber} />
            </Col>
          </Row>
        </>
      }
    </Step>
  );
}



// prefix={<Text textSize='tiny' pos="absolute" top="50%" left="0.75rem" transform="translateY(-50%)">Vardas:</Text>}
// in Input... p={{l: '4.5rem'}}

function CheckoutAcordion(props: Object) {

  const [activeStep, setActiveStep] = useState('');

  const {firstName, lastName, email, phoneNumber} = useSelector(getCheckoutState);
  const isCustInfoComplete = !!(firstName && lastName && email && phoneNumber);
  const isShippingComplete = !!useSelector(getFullShippingAddress);

  // Looks like there's a bug in Collapse - it doesn't work if isOpen=true on load.
  useEffect(() => {
    if (!activeStep) {
      if (!isCustInfoComplete) {
        setActiveStep('customer-info');
      }
      else if (isCustInfoComplete && !isShippingComplete) {
        setActiveStep('shipping');
      } else {
        setActiveStep('payment');
      }
    }
  }, [isShippingComplete, isCustInfoComplete, activeStep]);

  return (
    <>
      <CustomerInfoStep activeStep={activeStep} setActiveStep={setActiveStep} />
      <ShippingStep activeStep={activeStep} setActiveStep={setActiveStep} />
      <PaymentStep id='payment' isActive={activeStep === 'payment'} isComplete={false} title='3. Apmokėjimas' onEdit={() => setActiveStep('payment')} />
    </>
  );
}

export default function CheckoutView(props: Object) {

  const itemsCount = useSelector(getTotalCartItemsQty);

  return (
    <>
      <HtmlHead title='Užsakymas | bukitesveiki.lt.' meta={{robots: 'noindex'}} />
      <Text textAlign='center' tag='h1' textSize='title' textWeight='600'>Užsakymas</Text>

      <PersistGate keys={['checkout']}>
        {
          !!itemsCount ?
          <Div p={{t: '2rem', b: '2rem'}}>
            <Container>
              <Row>
                <Col size={{xs: '12', lg: '8'}} order={{xs: 2, lg: 1}}>
                  <CheckoutAcordion />
                </Col>
                <Col order={{xs: 1, lg: 2}}>
                  <CartSummary />
                </Col>
              </Row>
            </Container>
          </Div>
          : <Text p={{t: '4rem'}} textColor='gray900' textAlign='center'>Krepšelis tuščias</Text>
        }

      </PersistGate>
    </>
  );
}

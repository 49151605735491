// @flow

import React from 'react';

import Input from 'atomize/dist/atoms/input/Input';
import Div from 'atomize/dist/atoms/div/Div';
import Text from 'atomize/dist/atoms/text/Text';
import FormLabel from './FormLabel';

export default function FormField(props: Object) {

  const {children, label, required, error} = props;

  return (
    <Div p={{t: '0.5rem'}}>
      <FormLabel label={label} required={required} />
      {
        children
      }
      <Text textSize='tiny' textColor='danger700'>{error && error.message}</Text>

    </Div>
  )
}

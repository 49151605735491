// flow

import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';

import Div from 'atomize/dist/atoms/div/Div';
import Text from 'atomize/dist/atoms/text/Text';
import Row from 'atomize/dist/atoms/grid/Row';
import Col from 'atomize/dist/atoms/grid/Col';
import Button from 'atomize/dist/atoms/button/Button';
import Container from 'atomize/dist/atoms/grid/Container';
import Anchor from 'atomize/dist/atoms/anchor/Anchor';
import currentDevice from 'atomize/dist/functions/currentDevice';

import Collapse from '../Collapse';
import Box from './Box';

import CartItems from '../CartItems';

import {getCartItemsTotalPrice, getShippingFee, getTotalPrice} from '../../redux/selectors/cartSelectors';

function PricingSummary(props: Object) {

  const itemsPrice = useSelector(getCartItemsTotalPrice);
  const shippingFee = useSelector(getShippingFee);
  const totalPrice = useSelector(getTotalPrice);

  return (
    <>
    <Row>
      <Col textAlign='left'>
        <Text textColor='black600' textSize='caption'>Produktų kaina</Text>
      </Col>
      <Col textAlign='right'>
        <Text textColor='black600' textSize='caption'>{itemsPrice} €</Text>
      </Col>
    </Row>
    <Row p={{t: '0.25rem'}}>
      <Col textAlign='left'>
        <Text textColor='black600' textSize='caption'>Pristatymas</Text>
      </Col>
      <Col textAlign='right'>
        <Text textColor='black600' textSize='caption'>{shippingFee} €</Text>
      </Col>
    </Row>
    <Row p={{t: '0.25rem'}}>
      <Col textAlign='left'>
        <Text textColor='black600' textWeight='600'>Iš viso</Text>
      </Col>
      <Col textAlign='right'>
        <Text textColor='black600' textWeight='600'>{totalPrice} €</Text>
      </Col>
    </Row>
    </>
  )
}

export default function CartSummary(props: Object) {

  const [isOpen, setOpen] = useState(true);

  const deviceSize = currentDevice();
  const isSmallDevice = deviceSize === 'xs' || deviceSize === 'sm' || deviceSize === 'md';

  useEffect(() => {
    if (isSmallDevice) {
      setOpen(false);
    }
  }, [isSmallDevice]);

  return (
    <Box isOpen={isOpen} title='Krepšelis' titleBg='gray400' titleTextColor='black600' anchorComp={!isSmallDevice ? <></> : <Anchor textSize='tiny' textColor='black600' hoverTextColor='black600' textDecor="underline" onClick={() => setOpen(!isOpen)}>
        {isOpen ? 'Slėpti' : 'Rodyti'}
      </Anchor>}>
      <>
        <PricingSummary />
        <Div border={{t: '1px solid'}} borderColor='gray300' m={{t: '1rem'}}/>
        <CartItems id='cartSummary' textColor='black600' />
      </>
    </Box>
  );
}

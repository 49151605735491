// @flow

import React from 'react';

import Label from 'atomize/dist/atoms/label/Label';
import Text from 'atomize/dist/atoms/text/Text';

export default function FormLabel(props: Object) {

  const {children, required, label} = props;

  return (
    <Label textSize='caption'>
      {label}{required && <Text tag='span' textColor='danger700' p={{l: '0.25rem'}}>*</Text>}
      {children}
    </Label>
  )
}
